<template>
    <div>  
        <label v-if="label" :for="label" class="block text-xs sm:text-sm font-medium leading-5 text-gray-100">{{ label }}</label>
        <div class="mt-2 flex items-center">
            <span @click.prevent="open_file_input" class="inline-block h-20 w-20 rounded-full overflow-hidden bg-gray-100">
                <img v-if="file_url != ''" :src="file_url" alt="Profile photo" class="object-cover h-full w-full">
                <template v-else>
                    <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                </template>
            </span>
            <span class="ml-5 rounded-md shadow-sm">
                <button @click.prevent="open_file_input" type="button" class="py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-300 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                    {{ t('choose') }}
                </button>
            </span>
            <span class="ml-3" >{{ file_name }}</span>
            <input ref="input" type="file" @change="update_state" class="hidden-file-selector">
        </div>
        <template v-if="hasErrors">
            <p v-for="error in errors" :key="error" class="mt-2 text-sm text-red-600 font-medium">{{ error }}</p>
        </template>
    </div>
</template>

<script>
import useI18n from "@/modules/globalI18n";

export default {
    name: "ImageInput",
    setup() {
        return useI18n({
            componentPrefix: "ImageInput"
        });
    },
    props: ['value', 'errors', 'label', 'url_value'],
    computed:{
        hasErrors: function(){
            return this.errors && this.errors.length !== 0;
        },
        file_name: function(){
            if (this.value){
                return this.value.name;
            } else {
                return "No file chosen";
            }
        },
        file_url: function(){
            if (this.value){
                return window.URL.createObjectURL(this.value);
            } else {
                return this.url_value ?? "";
            }
        }
    },
    methods: {
        open_file_input(){
            this.$refs.input.click();
        },
        update_state(){
            const file_path = this.$refs.input.value;
            if (file_path){
                // eslint-disable-next-line
                const file_name = file_path.replace(/^.*[\\\/]/, '');

                const file = this.$refs.input.files[0];

                this.$emit('update:value', file);
                this.clearErrors();
            } else {
                this.$emit('update:value', null);
            }
        },
        clearErrors(){
            this.$emit('update:errors', []);
        }
    },
    // created(){
    //     if (this.value == ''){
    //         this.$emit('update:value', null);
    //     }
    // }
}
</script>
<style scoped>
    .hidden-file-selector {
        position: absolute; 
        left: -99999rem;
    }
</style>