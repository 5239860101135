<template>
   <PageWithButtonBelow :title="t('upload_photo')">
        <template v-slot:above-title>
            <TinyNavigation :prev="{}" />
        </template>
        <template v-slot:default>
            <div class="text-white px-10">
                <p class="">{{ t('to_continue_we_need_a_picture') }}</p>
                <p class="pt-2">{{ t('this_way_we_can_recognize') }}</p>

                <ImageInput v-model:value="form.profile_image" v-model:errors="errors.profile_image" :label="t('profile_image_label')" class="pt-6" />
            </div>
        </template>

        <template v-slot:button>
            <span @click.prevent="saveAndNavigate" class="block w-full rounded-md shadow-sm">
                <button type="button" class="button w-full">
                    {{ t('save_and_continue') }}
                </button>
            </span>
        </template>
    </PageWithButtonBelow>
</template>

<script>
import { updateProfile } from "@/helpers/api/AccountHelper";
import PageWithButtonBelow from "@/wrappers/PageWithButtonBelow";
import TinyNavigation from "@/components/TinyNavigation";
import ImageInput from "@/components/forms/ImageInput";
import { startSpinner, stopSpinner } from "@/helpers/storage/loadingSpinnerHelper";
import useI18n from "@/modules/globalI18n";

export default {
    name: "ReviewCreateUploadProfileImage",
    setup() {
        return useI18n({
            viewPrefix: "ReviewCreateUploadProfileImage"
        });
    },
    components: { PageWithButtonBelow, TinyNavigation, ImageInput },
    created(){
        
    },
    methods:{
        saveAndNavigate() {
            startSpinner();

            updateProfile(this.form)
                .then(() => {
                    this.$router.push({name: 'review.create', query: this.$route.query});
                })
                .catch(err => this.errors = err.errors)
                .finally(() => stopSpinner())
        }
    },
    data(){
        return {
            form: {
                profile_image: "",
            },
            errors: {
                profile_image: [],
            },
        }
    },
}
</script>
